<template>
    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
      <div class="row justify-content-center">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create New Category</h1>
              <hr>
              <div class="row">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                    <form @submit.prevent="postCategory" class="row p-2">
                        <div class="col-lg-12 form-group mb-3">
                            <label for="">Category Name</label>
                            <input v-model="category.name" type="text" required class="form-control text-grey-900 font-xsss fw-600">
                        </div>
                        <div class="col-xl-12 form-group text-right my-4">
                            <router-link :to="{name: 'EventCategoryAdmin'}" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                            <input type="Submit" class="btn btn-current" value="Submit">
                        </div>
                    </form>
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: "CreateEventCategoryAdmin",
        data(){
            return {
                category: {
                    name: '',
                },
                validationErrors: ''
            }
        },
        methods: {
            async postCategory(){
                var data = {
                    name: this.category.name,
                }
                await axios.post(`${process.env.VUE_APP_URL_API}/admin/events/category`, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                }).then(res => {
                    this.$swal({
                        toast: true,
                        title: 'Success Create New Category!',
                        text: res.data.message,
                        icon: 'success',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                    this.$router.push({ name: 'EventCategoryAdmin' })
                }).catch(err => {
                    if (err.response.status == 422){
                        this.validationErrors = Object.values(err.response.data.errors);
                        this.$swal({
                            toast: true,
                            title: 'Failed Create New Category!',
                            text: this.validationErrors[0][0],
                            icon: 'error',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    }
                })
            }
        }
    }
</script>
